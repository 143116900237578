import axios, { AxiosInstance } from "axios";

export default class StatusService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl?: string) {
    if (!serviceUrl) {
      throw new Error("You must specify a serviceUrl")
    }

    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
  }

  async status(): Promise<unknown> {
    return this.submit<unknown>("status", {});
  }

  // Helpers
  private async submit<T>(endpoint: string, payload: unknown) {
    return new Promise<T>((resolve, reject) => {
      this.client
        .post(endpoint, payload,{})
        .then(resp => resolve(resp.data))
        .catch(error => reject(error));
    });
  }
}