
import { Options, Vue } from "vue-class-component";
import LoginForm from "@/components/LoginForm.vue";
import store from "@/store"

@Options({
  components: {
    LoginForm,
  },
  created() {
    const {primary_color} = store.getters["styles/getStyles"];
    document.body.style.backgroundColor = primary_color;
  },
})
export default class Login extends Vue {}
